/* project.css */

.projects-container {
    max-width: 960px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
  }
  
  .projects-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .projects-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust for desired columns */
    gap: 20px;
  }
  
  .project-card {
    background-color: #f4f4f4;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
  }
  
  .project-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .project-info {
    padding: 20px;
  }
  
  .project-name {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .project-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
  }
  
  .project-tags {
    margin-bottom: 10px;
  }
  
  .tag {
    display: inline-block;
    background-color: #1d1836;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 8px;
    font-size: 0.8rem;
  }
  
  .project-links {
    margin-top: 10px;
  }
  
  .project-link {
    display: inline-block;
    text-decoration: none;
    color: #1d1836;
    background-color: #f4f4f4;
    border: 1px solid #1d1836;
    padding: 8px 16px;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
  }
  
  .project-link:hover {
    background-color: #1d1836;
    color: white;
  }
  