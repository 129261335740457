.footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo img {
  width: 120px;
  height: 120px;
}

.contact-me {
  text-align: center;
  flex-grow: 1;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icons a img {
  width: 30px;
  height: 30px;
}

.footer-copy {
  margin-top: 20px;
}
