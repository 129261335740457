.skills-container {
  padding: 50px;
  text-align: center;
}

.skills-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.skill-card {
  width: 300px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.skill-info {
  text-align: left;
}

.skill-name {
  font-size: 24px;
  margin-bottom: 10px;
}

.skill-progress {
  height: 15px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.skill-progress-bar {
  height: 100%;
  background-color: #1d1836;
  border-radius: 10px;
}

.skill-level {
  font-size: 14px;
  margin-top: 10px;
  color: #888;
}

.skill-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.skill-icon {
  text-align: center;
  margin-bottom: 10px;
}

.skill-icon img {
  width: 80px;
  height: 80px;
}

@media screen and (max-width: 767px) {
  .skills-list {
    justify-content: flex-start;
  }

  .skill-card {
    flex: 0 1 calc(50% - 20px);
  }

  .skill-name {
    font-size: 20px;
  }
  .skills-section {
    margin-top: 700px; /* Adjust spacing between the paragraph and the Skills section */
  }
}
