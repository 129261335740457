body, div, img {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  /* background-color: #f1eeea; */
}

.row {
  display: grid;
  justify-content: center;
}

.container {
  position: relative; /* Needed for positioning the overlay div */
  width: 100%;
  height: 350px;
}

.image {
  position: relative; /* Needed for positioning the overlay div */
  width: 100vw;
  height: 100%;
}

img {
  width: 100vw;
  height: 350px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 350px;
  background-color: rgba(0, 0, 0, 0.2); /* Adjust the opacity (last value) to control the tint */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f1eeea;
  font-size: 24px;
  z-index: 1;
  word-wrap: break-word;
  text-align: center;
  line-height: 1.5;
  font-family: 'Source Sans Pro', sans-serif;
}

.font {
  font-size: 20px;
}

.containe {
  display: flex;
}

.side {
  width: 30%;
  padding: 10px;
}


.side-content-center {
  display: flex;
  align-items: center;
  line-height: 2;
  width: 70%;
}

.side-img {
  width: 165px;
  height: 105px;
  position: relative;
  left: 40%;
  top: 10%;
}

.blog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.blog_button {
  appearance: none;
  background-color: #000000;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 50px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 120px;
  will-change: transform;
}

.blog_button:disabled {
  pointer-events: none;
}

.blog_button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.blog_button:active {
  box-shadow: none;
  transform: translateY(0);
}
 .social {
  display: flex;
  filter: black(100%);
  /* z-index: 2; */
  padding-left: 10px;
  float: right;
  /* align-items: center;
  justify-content: center; */
  position: relative;
 }

 .social img {
  margin-right: 20px;
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 767px) {

  .social {
    align-items: center;
    justify-content: center;
    width: 100vw;
  }
 .test {
  display: none;
 }

  .side-content-center {
    position: absolute;
    width: 100vw;
  }
  .blog {
    bottom: 124px;
    /* z-index: 3; */
    width: 140px; 
  }
  .blog_button{
    position: relative;
    left: 115px;
    top: 25px;
  }

  .icons img{
    display: none;
  }

  .footer{
    display: none;
  }

  .p img {
    width: 10px;
    height: 1opx;
  }
  
}

.icons img {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0px
}


