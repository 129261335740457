/* .resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  body {
    background-color: #f1eeea;
  }
  
.resume img {
    width: 80%;
    height: 80%;
} */
/* resume.css */
/* Global Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Navbar */
.navbar {
  background-color: #222;
  color: white;
  padding: 10px;
  text-align: center;
}

/* Footer */
.footer {
  background-color: #222;
  color: white;
  text-align: center;
  padding: 10px;
}

/* Experience Section */
.resume-section {
  padding: 50px;
  text-align: center;
}

.resume-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Timeline */
.timeline {
  margin: 0 auto;
  max-width: 800px;
}

.timeline-element {
  position: relative;
  padding: 20px;
  margin-bottom: 40px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

.timeline-element h3 {
  font-size: 24px;
  margin: 0;
}

.timeline-element p {
  font-size: 16px;
  margin: 10px 0;
}

.timeline-icon {
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: #222;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply animation to elements with 'animate' class */
.animate {
  animation: fadeIn 1s ease-in-out;
}

.custom-date {
  color: black !important;
  font-weight: bold !important;
}