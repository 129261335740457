* {
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "sans-serif";
  background-color:#f1eeea;  
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.container {
  margin: 1rem;
  text-align: center;
}

.nav {
  background-color: #171717;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .nav{
    font-size: 13px;
    justify-content: center;
}
.HomeImage{
  width: 25px;
  height: 25px;
  position: relative;
  float: left;
}
}



.nav a {
  padding: 0.5rem 1rem;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 20px;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}

.nav li.active {
  background-color: #171717;
}

.nav li:hover {
  background-color: grey; /* Change the hover color to grey */
}

.HomeImage {
  width: 50px;
  height: 50px;
  position: relative;
  float: left;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.home-button-container {
  display: flex;
  align-items: center;
}

.home {
  margin: 0;
  margin-right: 1rem;
}

/* .my-blogs-button {
  background-color: #171717;
  border: none;
  border-radius: 50%;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
} */



/* CSS */
