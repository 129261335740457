/* Navbar styling */
.navbar {
  background-color: #171717;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 2;
}
  
  .logo img {
    width: 75px;
    height: auto;
    filter: invert(1);
  }
  
  .menu {
    display: none;
    transition: o.3s ease-in-out;
  }
  
  .menu.active {
    display: block;
    top: 85px;
    right: 55px;
    width: 163px;
    height: 380px;
    z-index: 6;
    font-weight: 500;
    line-height: 3;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
  }
  
  .menu li {
    margin: 10px 0;
    font-size: 20px;
  }

  .menu a:hover,
  .menu a.active {
    color: gray;
    text-decoration: underline;
  }
  
  .menu a {
    text-decoration: none;
    color: white;
    transition: color 0.3s;
  }
  
  /* Hamburger menu toggle */
  .menu-toggle {
    display: none;
    cursor: pointer;
  }
  
  @media screen and (max-width: 767px) {
    .menu-toggle {
      display: block;
    }
    
    .logo img{
        width: 75px;
        height: auto;
    }
    .menu {
      position: absolute;
      top: 80px;
      right: 0;
      background-color: #333;
      border-radius: 4px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
  
    .menu ul {
      padding: 20px;
    }
  
    .menu li {
      margin: 10px 0;
    }
  
    .menu-toggle img {
      width: 30px;
      height: auto;
    }
  }

  @media screen and (min-width: 768px) {
    .menu {
      display: block; /* Display the menu links on desktop */
      position: static;
      background-color: transparent;
      box-shadow: none;
    }
  
    .menu-toggle {
      display: none; /* Hide the menu toggle on desktop */
    }
  
    .menu ul {
      display: flex;
    }
  
    .menu li {
      margin: 0 25px;
      font-size: 18px;
    }
  
    .menu-toggle img {
      display: none;
    }
  }